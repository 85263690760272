import React from 'react';
import Button from '../components/buttons/button';
import { Route } from '../models/site-links';
import SEO from '../components/seo';

const ThanksPricingPage = () => {
  return (
    <div>
      <SEO title="Thank You - Patronscan" />
      <div className="max-w-container min-h-screen mx-auto flex flex-col items-center justify-center">
        <h2 className="font-section-headers mb-5 ml-5 mr-5 text-3xl text-center">
          Thank You for Your interest in Patronscan! One of our experts will be in touch with your
          shortly.
        </h2>
        <Button buttonId="PricingThanks:GoHome" link={Route.Home} text="Go Home" blue />
      </div>
    </div>
  );
};

export default ThanksPricingPage;
